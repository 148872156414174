@font-face {
    font-family: 'RedHatDisplay';
    src: url('fonts/RedHatDisplay-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

/*@font-face {*/
/*    font-family: 'RedHatDisplay';*/
/*    src: url('fonts/RedHatDisplay-Bold.ttf') format('truetype');*/
/*    font-weight: bolder;*/
/*    font-style: normal;*/
/*}*/

@font-face {
    font-family: 'RedHatDisplay';
    src: url('fonts/RedHatDisplay-Light.ttf') format('truetype');
    /*same as light font weight*/
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'RedHatDisplay';
    src: url('fonts/RedHatDisplay-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'RedHatDisplay';
    src: url('fonts/RedHatDisplay-Medium.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

h2 {
    font-size: 28px; /* Customize the font size for h2 */
}

h4 {
    font-size: 24px; /* Customize the font size for h4 */
    font-weight: bold;
}

h6 {
    font-size: 16px; /* Customize the font size for h6 */
}

.text {
    font-family: 'RedHatDisplay', serif;
    font-size: 16px;
    font-weight: normal;
}

.lightText {
    font-family: 'RedHatDisplay', serif;
    font-size: 14px;
    font-weight: 300;
}

.heading {
    font-family: 'RedHatDisplay', serif;
    font-size: 28px;
    font-weight: bold;
}

.subheading {
    font-family: 'RedHatDisplay', serif;
    font-size: 16px;
    font-weight: bold;
}

.button {
    background-color: #2F226D;
    color: whitesmoke;
}

.custom-nav-pills .nav-link.active {
    background-color: #2F226D; /* Replace with your desired color */
    color: whitesmoke; /* Replace with your desired text color */
    text-align: left;
}

.custom-nav-pills .nav-link {
    background-color: white;
    text-align: left;
}